import "./styles/main.scss";

import Alpine from "alpinejs";

// import Plyr from 'plyr';

const players = Plyr.setup(".player");

window.Alpine = Alpine;

Alpine.start();

let links = document.querySelectorAll("a:not(.external-link)[target]");

let popup = document.querySelector(".popup");
let closePopupButton = document.querySelector(".popup .close-popup");
let externalLink = document.querySelector(".popup .external-link");

// loop over links
for (let link of links) {
  // when you click on a link
  link.addEventListener("click", (e) => {
    // get the href value
    let url = link.href;

    // check if the url is external
    if (new URL(url).origin !== location.origin) {
      // If so prevent browser from going to the link and open the popup
      e.preventDefault();
      openPopup(url);
    }
  });
}

// function that opens the popup
function openPopup(href) {
  // add the class that shows the popup

  popup.classList.remove("hidden");
  popup.classList.add("flex");

  // set the href of the link inside the popup to the external site
  externalLink.href = href;

  externalLink.addEventListener("click", (e) => {
    popup.classList.remove("flex");
    popup.classList.add("hidden");
  });
}

// function that closes the popup
function closePopup(e) {
  // remove the class that shows the popup
  e.preventDefault();
  popup.classList.remove("flex");
  popup.classList.add("hidden");
}

// Close the popup when clicking the close button
closePopupButton.addEventListener("click", closePopup);

// if (players?.length) {
//   for (let i = 0; i < players.length; i++) {
//     players[i].on("playing", (event) => {
//       const instance = event.detail.plyr;

//       const video = event.target.children[1].children[0];

//       console.log("Triggered " + video.id);
//       // gtag("event", "video_start", {
//       //   video_title: video.id,
//       // });
//     });
//   }
// }
// Set to track IDs of videos that have been played
const playedVideos = new Set();

// Function to pause all players except the one currently playing
function pauseOtherPlayers(currentPlayer) {
  players.forEach((player) => {
    if (player !== currentPlayer) {
      player.pause();
    }
  });
}

// Attach event listener to each player
players.forEach((player) => {
  player.on("play", (event) => {
    // Pause other players
    pauseOtherPlayers(player);

    // Get the ID of the currently playing video's container
    const currentPlayingId =
      player.elements.container.children[1].children[0].id;

    // Check if this video has not been played before
    if (!playedVideos.has(currentPlayingId)) {
      console.log("First time playing video ID:", currentPlayingId);
      // Add the ID to the set
      playedVideos.add(currentPlayingId);

      gtag("event", "video_start", {
        video_title: currentPlayingId,
      });
    }
  });
});

const supporters = document.getElementById("supporters");

if (supporters) {
  supporters.addEventListener("click", (event) => {
    if (event.target.tagName === "A") {
      gtag("event", "link_click", {
        link_text: event.target.innerText,
        link_url: event.target.href,
        link_id: event.target.id,
      });
    }
  });
}

// custom jumpTo time function

const jumpTo = (identifier, time) => {
  console.log(identifier, time);
  players.forEach((player) => {
    if (player.config.title === identifier) {
      player.currentTime = +time;
      player.play();
    }
  });
};

const elements = document.querySelectorAll(".jumper");
elements.forEach((element) => {
  element.addEventListener("click", (event) => {
    jumpTo(element.dataset.identifier, element.dataset.duration);
  });
});
